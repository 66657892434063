import React, { useEffect, useState } from "react"
import { observer } from "mobx-react";
import { navigate } from "gatsby";
import { Backdrop, Box, Button, CircularProgress, CssBaseline, Divider, Fade, Modal, Paper, TextField, Typography } from "@mui/material";
import Layout from "../../components/shared-layout";
import useStores from "../../hooks/use-stores";
import VmTable from "../../components/shared-table";
import VmButton from "../../components/shared-button";
import VmModal from "../../components/shared-modal";
import Breadcrumb from "../../components/shared-breadcrumb";
import { ApiDataListType, ApiDataResponseListLengthCheck, GetCollegeId } from "../../constants/options";
import { useTranslation } from "react-i18next";
import useNotification from "../../components/notification";
import ContentLayout from "../../components/shared-content-layout";
import { TD_FIRST, TD_LAST, TD_NORMAL } from "../../constants/style";

const YearLevelIndexPage = observer(() => {
  const { academicStore, rootStore } = useStores();
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  // Determine is update or create
  const [targetYearLevel, setTargetYearLevel] = useState<any>();
  const [yearLevel, setYearLevel] = useState<string>("1");
  const [description, setDescription] = useState<string>("");

  useEffect(() => {
    if (ApiDataResponseListLengthCheck(ApiDataListType.YEAR_LEVEL) == 0) {
      academicStore.getYearLevelList();
    }
  }, []);

  const onActionYearLevel = () => {
    let req: any = {
      id: 0,
      yearLevelInt: yearLevel,
      yearLevelDescription: description,
      collegeId: GetCollegeId(),
    }
    if (targetYearLevel) {
      req.id = targetYearLevel.id;
      academicStore.updateYearLevel(req).then(() => {
        rootStore.notify(t('YEAR_LEVEL') + t('UPDATED_B'), 'success');
        academicStore.getYearLevelList();
        handleCloseModal();
      }).catch(() => rootStore.notify('UPDATE_FAILED'));
    } else {
      academicStore.createYearLevel(req).then(() => {
        rootStore.notify(t('YEAR_LEVEL') + t('CREATED_B'), 'success');
        academicStore.getYearLevelList();
        handleCloseModal();
      }).catch(() => rootStore.notify('CREATE_FAILED'));
    }
  }

  const handleShowModal = (id?: number) => {
    if (id) {
      let target = academicStore.yearLevelList.find(yl => yl.id == id);
      setTargetYearLevel(target);
      setYearLevel(target.yearLevelInt);
      setDescription(target.yearLevelDescription);
    }
    setShowModal(true);
  }

  const handleCloseModal = () => {
    setShowDeleteModal(false);
    setTargetYearLevel(null);
    setYearLevel("1");
    setDescription("");
    setShowModal(false);
  }

  const handleDeleteModal = (id: number) => {
    let target = academicStore.yearLevelList.find(yl => yl.id == id);
    setTargetYearLevel(target);
    setShowDeleteModal(true);
  }

  return (
    <ContentLayout
      pageName={t('YEAR_LEVEL')}
      pageHeading={t('YEAR_LEVEL') + t('LIST_B')}
      breadCrumb={[
        { label: t('YEAR_LEVEL') + t('MODULE_B'), to: "/year-level-module" },
        { label: t('OVERVIEW'), to: "" }
      ]}
      buttonLabel={t('ADD_A') + t('YEAR_LEVEL')}
      onClickAction={() => handleShowModal()}
    >
      <VmTable
        loading={academicStore.loading}
        thead={["ID", t('YEAR_LEVEL'), t('YEAR_LEVEL') + t('DESCRIPTION_B'), t('ACTION')]}
        tbody={academicStore.yearLevelList.length > 0 &&
          academicStore.yearLevelList.map((data, index) => (
            <>
              <Box sx={{ marginY: 1 }} />
              <tr key={`year_level_list_${index}`}>
                <td className={TD_FIRST}>{index + 1}</td>
                <td className={TD_NORMAL}>{data.yearLevelInt}</td>
                <td className={TD_NORMAL}>{data.yearLevelDescription}</td>
                <td className={TD_LAST}>
                  <Button
                    variant="outlined"
                    sx={{ marginRight: 2 }}
                    onClick={() => handleShowModal(data.id)}
                  >
                    {t('EDIT')}
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleDeleteModal(data.id)}
                  >
                    {t('DELETE')}
                  </Button>
                </td>
              </tr>
              <Box sx={{ marginY: 1 }} />
            </>
          ))
        }
        // page={2}
        // paginationCount={10}
        // onChangePagination={() => console.log("wdnmd")}
      />
      <VmModal
        openModal={showModal}
        onClose={handleCloseModal}
        title={
          targetYearLevel ? t('UPDATE_A') + t('YEAR_LEVEL')
            : t('ADD_A') + t('YEAR_LEVEL')}
        buttonLabel={targetYearLevel ? t('CONFIRM_A') + t('UPDATE') : t('CONFIRM_A') + t('CREATION')}
        buttonLabelWithoutConfirm={targetYearLevel ? t('UPDATE_NOW') : t('CREATE_NOW')}
        onClickConfirmedButton={onActionYearLevel}
        disabledCondition={yearLevel === "0" || description === ""}
      >
        <TextField
          value={yearLevel}
          type="number"
          label={t('YEAR_LEVEL')}
          variant="outlined"
          sx={{ width: '100%', marginBottom: 2 }}
          onChange={(value) => setYearLevel(value.target.value)}
        />
        <TextField
          value={description}
          label={t('DESCRIPTION')}
          variant="outlined"
          sx={{ width: '100%' }}
          rows={4}
          multiline
          onChange={(value) => setDescription(value.target.value)}
        />
      </VmModal>

      <VmModal
        openModal={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        title={t('DELETE_A') + t('CONFIRMATION')}
        buttonLabel={t('CONFIRM_DELETE')}
        buttonLabelWithoutConfirm={t('DELETE_NOW')}
        onClickConfirmedButton={() => academicStore.deleteYearLevel(targetYearLevel.id).then(() => {
          rootStore.notify(t('YEAR_LEVEL') + t('DELETED_B'), 'success');
          academicStore.getYearLevelList();
          handleCloseModal();
        }).catch(() => rootStore.notify('DELETE_FAILED', 'error'))}
      >
        <Typography sx={{ marginBottom: 2 }}>{t('DELETE_CHECK')}?</Typography>
        <Typography color="red" variant="caption">
          {t('DELETE_INFO_YEAR_LEVEL')}
        </Typography>
      </VmModal>
    </ContentLayout>
  )
})

export default YearLevelIndexPage;
